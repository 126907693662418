import React from "react";
import "./Workshops.css";
const poster = require("./assets/Workshop_Poster.png");

function Workshops() {
  return (
    <div className="workshops">
      <div className="heading1 abstract_mb40">Pre Conference Workshop</div>
      <div className="poster-text">
          {/* <div className="workshop-text">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reiciendis
            magnam beatae illo voluptatum inventore est corrupti, saepe ducimus
            ullam blanditiis magni porro dolor harum repellendus doloribus numquam
            cum hic pariatur eius ipsum voluptas quas praesentium doloremque?
            Laudantium, quaerat dicta? Distinctio in blanditiis aspernatur
            praesentium labore, perspiciatis sunt libero illum voluptate dicta earum
            delectus quo beatae illo? Quisquam vel laboriosam odit.
          </div> */}
          <img src={poster} alt="Workshop poster" className="poster_img"/>
      </div>
    </div>
  );
}

export default Workshops;
