import React from "react";
import "./Sponsors.css";
const rsc_logo = require("./assets/rsc_logo1.png");
const springer_logo = require("./assets/SN_stack_logo.jpg");
function Sponsors() {
  return (
    <div className="sponsors">
      <div className="heading1">Sponsors</div>

      <div className="spons_div">
        <div className="element">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier1" />
          {/* <div className="sp_4" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>*/}
          <img src={springer_logo} alt="rsc_logo" className="logo_tier1" />
        </div>
      </div>
      {/* <div className="spons_2">
        <div className="element_2">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier2" />
          <div className="sp_4_2" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
        <div className="element_2">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier2" />
          <div className="sp_4_2" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
        <div className="element_2">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier2" />
          <div className="sp_4_2" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
        <div className="element_2">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier2" />
          <div className="sp_4_2" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
      </div>
      <div className="spons_3">
        <div className="element_3">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier3" />
          <div className="sp_4_3" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
        <div className="element_3">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier3" />
          <div className="sp_4_3" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
        <div className="element_3">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier3" />
          <div className="sp_4_3" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
        <div className="element_3">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier3" />
          <div className="sp_4_3" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
        <div className="element_3">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier3" />
          <div className="sp_4_3" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
        <div className="element_3">
          <img src={rsc_logo} alt="rsc_logo" className="logo_tier3" />
          <div className="sp_4_3" style={{ textAlign: "left" }}>
            Royal Society of Chemistry
          </div>
        </div>
      </div> */}



      {/* !!! IMPORTANT DO NOT UNCOMMENT BELOW THIS*/}
      
      
      
      {/* <div className="element">
          <div className="heading4 sp_4" style={{ textAlign: "left" }}>
            Lorem Ipsum
          </div>
          <div className="heading6">Professor</div>
          <div className="para">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perferendis pariatur aperiam sequi magni fugiat quos voluptatibus
            maxime dolor, provident tempora
          </div>
        </div>
        <div className="element">
          <div className="heading4 sp_4" style={{ textAlign: "left" }}>
            Lorem Ipsum
          </div>
          <div className="heading6">Professor</div>
          <div className="para">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perferendis pariatur aperiam sequi magni fugiat quos voluptatibus
            maxime dolor, provident tempora
          </div>
        </div>
        <div className="element">
          <div className="heading4 sp_4" style={{ textAlign: "left" }}>
            Lorem Ipsum
          </div>
          <div className="heading6">Professor</div>
          <div className="para">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perferendis pariatur aperiam sequi magni fugiat quos voluptatibus
            maxime dolor, provident tempora
          </div>
        </div>
        <div className="element">
          <div className="heading4 sp_4" style={{ textAlign: "left" }}>
            Lorem Ipsum
          </div>
          <div className="heading6">Professor</div>
          <div className="para">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perferendis pariatur aperiam sequi magni fugiat quos voluptatibus
            maxime dolor, provident tempora
          </div>
        </div>
        <div className="element">
          <div className="heading4 sp_4" style={{ textAlign: "left" }}>
            Lorem Ipsum
          </div>
          <div className="heading6">Professor</div>
          <div className="para">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perferendis pariatur aperiam sequi magni fugiat quos voluptatibus
            maxime dolor, provident tempora
          </div>
        </div>
        <div className="element">
          <div className="heading4 sp_4" style={{ textAlign: "left" }}>
            Lorem Ipsum
          </div>
          <div className="heading6">Professor</div>
          <div className="para">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perferendis pariatur aperiam sequi magni fugiat quos voluptatibus
            maxime dolor, provident tempora
          </div>
        </div>
        <div className="element">
          <div className="heading4 sp_4" style={{ textAlign: "left" }}>
            Lorem Ipsum
          </div>
          <div className="heading6">Professor</div>
          <div className="para">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perferendis pariatur aperiam sequi magni fugiat quos voluptatibus
            maxime dolor, provident tempora
          </div>
        </div>
        <div className="element">
          <div className="heading4 sp_4" style={{ textAlign: "left" }}>
            Lorem Ipsum
          </div>
          <div className="heading6">Professor</div>
          <div className="para">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perferendis pariatur aperiam sequi magni fugiat quos voluptatibus
            maxime dolor, provident tempora
          </div>
        </div> */}
    </div>
  );
}

export default Sponsors;
